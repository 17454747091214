import React from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import webDev from '../../../images/webDev.svg';
import mobileApp from '../../../images/mobileApp.svg';
import migration from '../../../images/migration.svg';
import API from '../../../images/API.svg';
import pythonGame from '../../../images/pythonGame.svg';
import Machine from '../../../images/Machine.svg';
import { Api } from '../../../Api';
import ReactCardFlip from 'react-card-flip';


class Services extends React.Component {
    constructor() {
        super();
        this.state = {
            isFlipped: false,
            isFlip1:false,
            isFlip2:false,
            isFlip3:false,
            isFlip4:false,
            isFlip5:false,
            isFlip6:false,
        };
    }

    handleFlipOpen = (event, flip)=> {
        event.preventDefault();
        if(flip == 1){
            this.setState(prevState => ({ isFlip1: true }));
        }
        if(flip == 2){
            this.setState(prevState => ({ isFlip2: true }));
        }
        if(flip == 3){
            this.setState(prevState => ({ isFlip3: true }));
        }
        if(flip == 4){
            this.setState(prevState => ({ isFlip4: true }));
        }
        if(flip == 5){
            this.setState(prevState => ({ isFlip5: true }));
        }
        if(flip == 6){
            this.setState(prevState => ({ isFlip6: true }));
        }
        console.log(flip);
    }
    handleFlipClose = (event, flip)=> {
        event.preventDefault();
        if(flip == 1){
            this.setState(prevState => ({ isFlip1: false }));
        }
        if(flip == 2){
            this.setState(prevState => ({ isFlip2: false }));
        }
        if(flip == 3){
            this.setState(prevState => ({ isFlip3: false }));
        }
        if(flip == 4){
            this.setState(prevState => ({ isFlip4: false }));
        }
        if(flip == 5){
            this.setState(prevState => ({ isFlip5: false }));
        }
        if(flip == 6){
            this.setState(prevState => ({ isFlip6: false }));
        }
        console.log(flip);
    }
    
    Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }));

    render() {
        return (
            <>
            <Grid className="lightColour">
                <Container maxWidth="lg" sx={{ py: 5}}>
                    <Typography className="" variant="h4" component="h4" sx={{textTransform:'uppercase', textAlign:'center'}}>Our <strong>Services</strong></Typography>
                    <div class="bar"></div>
                    <Grid container item direction="row" spacing={3} className="serviceBlock" sx={{ py: 5}}>
                        <Grid item sm={6} lg={4} xs={12}>
                            <ReactCardFlip isFlipped={this.state.isFlip1} flipDirection="horizontal">
                                <div onMouseEnter={(e) => this.handleFlipOpen(e, 1)}>
                                    <Card sx={{ minHeight: 255, textAlign: 'center', borderRadius:'10px'}}>
                                        <Box className="serviceIcon" sx={{textAlign:'center', p:1, mt:5, minHeight: 90}}><img src={webDev} alt="Compatible" height="50px"/></Box>
                                        <Typography className="NavyColourText" variant="h6" component="h6" sx={{ my: 2, textTransform:'uppercase', fontWeight: '600' }} >Python <br />Web Development</Typography>
                                    </Card>
                                </div>
                                <div onMouseLeave={(e) => this.handleFlipClose(e, 1)}>
                                    <Card sx={{ minHeight: 255, textAlign: 'center', borderRadius:'10px', color:'white', backgroundColor: 'primary.main'}}>
                                        <Typography variant="body1" component="div" sx={{fontWeight:'500', fontSize:'0.9rem', display:'flex', alignItems:'center', boxSizing:'border-box', p:3}}>
                                        Hire certified Python developers that have experience in developing high-performing, stable, secure, and highly dependable Python websites.
                                        </Typography>
                                        <Box><Button variant="contained" color="primary" size="medium" href="#">Read More</Button></Box>
                                    </Card>
                                </div>
                            </ReactCardFlip>
                        </Grid>
                        <Grid item sm={6} lg={4} xs={12}>
                            <ReactCardFlip isFlipped={this.state.isFlip2} flipDirection="horizontal">
                                <div onMouseEnter={(e) => this.handleFlipOpen(e, 2)}>
                                    <Card sx={{ minHeight: 255, textAlign: 'center', borderRadius:'10px'}}>
                                        <Box className="serviceIcon" sx={{textAlign:'center', p:1, mt:5, minHeight: 90}}><img src={mobileApp} alt="Compatible" height="60px"/></Box>
                                        <Typography className="NavyColourText" variant="h6" component="h6" sx={{ my: 2, textTransform:'uppercase', fontWeight: '600' }} >Python <br />Applications</Typography>
                                    </Card>
                                </div>
                                <div onMouseLeave={(e) => this.handleFlipClose(e, 2)}>
                                    <Card sx={{ minHeight: 255, textAlign: 'center', borderRadius:'10px', color:'white', backgroundColor: 'primary.main'}}>
                                        <Typography variant="body1" component="div" sx={{fontWeight:'500', fontSize:'0.9rem', display:'flex', alignItems:'center', boxSizing:'border-box', p:3}}>
                                        Our skillful Python developers are well-versed with Python tools and frameworks like Django and have a shared vision to help businesses rapidly develop server-side web applications.
                                        </Typography>
                                        <Box><Button variant="contained" color="primary" size="medium" href="#">Read More</Button></Box>
                                    </Card>
                                </div>
                            </ReactCardFlip>
                        </Grid>
                        <Grid item sm={6} lg={4} xs={12}>
                            <ReactCardFlip isFlipped={this.state.isFlip3} flipDirection="horizontal">
                                <div onMouseEnter={(e) => this.handleFlipOpen(e, 3)}>
                                    <Card sx={{ minHeight: 255, textAlign: 'center', borderRadius:'10px'}}>
                                        <Box className="serviceIcon" sx={{textAlign:'center', p:1, mt:5, minHeight: 90}}><img src={migration} alt="Compatible" height="60px"/></Box>
                                        <Typography className="NavyColourText" variant="h6" component="h6" sx={{ my: 2, textTransform:'uppercase', fontWeight: '600' }} >Python Migration <br />& Integration Services</Typography>
                                    </Card>
                                </div>
                                <div onMouseLeave={(e) => this.handleFlipClose(e, 3)}>
                                    <Card sx={{ minHeight: 255, textAlign: 'center', borderRadius:'10px', color:'white', backgroundColor: 'primary.main'}}>
                                        <Typography variant="body1" component="div" sx={{fontWeight:'500', fontSize:'0.9rem', display:'flex', alignItems:'center', boxSizing:'border-box', p:3}}>
                                        Let our reliable offshore Python coders make your existing applications secure and robust by migrating them to Python.
                                        </Typography>
                                        <Box><Button variant="contained" color="primary" size="medium" href="#">Read More</Button></Box>
                                    </Card>
                                </div>
                            </ReactCardFlip>
                        </Grid>
                        <Grid item sm={6} lg={4} xs={12}>
                            <ReactCardFlip isFlipped={this.state.isFlip4} flipDirection="horizontal">
                                <div onMouseEnter={(e) => this.handleFlipOpen(e, 4)}>
                                    <Card sx={{ minHeight: 255, textAlign: 'center', borderRadius:'10px'}}>
                                        <Box className="serviceIcon" sx={{textAlign:'center', p:1,  mt:5, minHeight: 90}}><img src={API} alt="Compatible" height="60px"/></Box>
                                        <Typography className="NavyColourText" variant="h6" component="h6" sx={{ my: 2, textTransform:'uppercase', fontWeight: '600' }} >API Development <br />Services</Typography>
                                    </Card>
                                </div>
                                <div onMouseLeave={(e) => this.handleFlipClose(e, 4)}>
                                    <Card sx={{ minHeight: 255, textAlign: 'center', borderRadius:'10px', color:'white', backgroundColor: 'primary.main'}}>
                                        <Typography variant="body1" component="div" sx={{fontWeight:'500', fontSize:'0.9rem', display:'flex', alignItems:'center', boxSizing:'border-box', p:3}}>
                                        With our web services and APIs, make iOS, Android, and web applications accessible to your platform data in a secure, stable, and well-documented manner.
                                        </Typography>
                                        <Box><Button variant="contained" color="primary" size="medium" href="#">Read More</Button></Box>
                                    </Card>
                                </div>
                            </ReactCardFlip>
                        </Grid>
                        <Grid item sm={6} lg={4} xs={12}>
                            <ReactCardFlip isFlipped={this.state.isFlip5} flipDirection="horizontal">
                                <div onMouseEnter={(e) => this.handleFlipOpen(e, 5)}>
                                    <Card sx={{ minHeight: 255, textAlign: 'center', borderRadius:'10px'}}>
                                        <Box className="serviceIcon" sx={{textAlign:'center', p:1, mt:5, minHeight: 90}}><img src={pythonGame} alt="Compatible" height="60px"/></Box>
                                        <Typography className="NavyColourText" variant="h6" component="h6" sx={{ my: 2, textTransform:'uppercase', fontWeight: '600' }} >Python <br />Game Development</Typography>
                                    </Card>
                                </div>
                                <div onMouseLeave={(e) => this.handleFlipClose(e, 5)}>
                                    <Card sx={{ minHeight: 255, textAlign: 'center', borderRadius:'10px', color:'white', backgroundColor: 'primary.main'}}>
                                        <Typography variant="body1" component="div" sx={{fontWeight:'500', fontSize:'0.9rem', display:'flex', alignItems:'center', boxSizing:'border-box', p:3}}>
                                        We program console, mobile, desktop, VR/AR, and browser games with the Python game development framework, PyGame. We use Python best framework to design custom simulations, architectures and graphics. 
                                        </Typography>
                                        <Box><Button variant="contained" color="primary" size="medium" href="#">Read More</Button></Box>
                                    </Card>
                                </div>
                            </ReactCardFlip>
                        </Grid>
                        <Grid item sm={6} lg={4} xs={12}>
                            <ReactCardFlip isFlipped={this.state.isFlip6} flipDirection="horizontal">
                                <div onMouseEnter={(e) => this.handleFlipOpen(e, 6)}>
                                    <Card sx={{ minHeight: 255, textAlign: 'center', borderRadius:'10px'}}>
                                        <Box className="serviceIcon" sx={{textAlign:'center', p:1, mt:5, minHeight: 90}}><img src={Machine} alt="Compatible" height="60px"/></Box>
                                        <Typography className="NavyColourText" variant="h6" component="h6" sx={{ my: 2, textTransform:'uppercase', fontWeight: '600' }} >AI & Machine <br />Learning APPS</Typography>
                                    </Card>
                                </div>
                                <div onMouseLeave={(e) => this.handleFlipClose(e, 6)}>
                                    <Card sx={{ minHeight: 255, textAlign: 'center', borderRadius:'10px', color:'white', backgroundColor: 'primary.main'}}>
                                        <Typography variant="body1" component="div" sx={{fontWeight:'500', fontSize:'0.9rem', display:'flex', alignItems:'center', boxSizing:'border-box', p:3}}>
                                        We implement Python machine learning protocols including k-means clustering, decision tree classification, linear and logistic regression to provide businesses with the best natural language technologies.
                                        </Typography>
                                        <Box><Button variant="contained" color="primary" size="medium" href="#">Read More</Button></Box>
                                    </Card>
                                </div>
                            </ReactCardFlip>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
           </>
            
        )
    }
}

export default Services