import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import django from '../../../images/django.webp';
import postgree from '../../../images/postgree.png';
import python from '../../../images/python.webp';
import javascript from '../../../images/javascript.png';
import industrial from '../../../images/industrial.png';



const CaseStudyDetail = () => {
  return (
    <>
    <Box className="lightGrey" sx={{ py:7 }}>
        <Container maxWidth="xl">
            <Grid item xs={12}>
              <Typography variant="h6" sx={{color:'primary.main', mb:0, textAlign:'center', textTransform:'uppercase'}}>Case Study</Typography>
              <Typography variant="h4" sx={{mb:0, textAlign:'center', textTransform:'uppercase'}}>Industrial <strong>Equipment</strong></Typography>
            </Grid>
            <Grid container items display="row" spacing={3} sx={{alignItems:'center', flexDirection:'row-reverse'}}>
                <Grid item md={6} xs={12}><img src={industrial} alt="industrial" width="100%" /></Grid>
                <Grid item md={6} xs={12}>
                     <Typography variant="h5" component="h5" sx={{mb:1, fontWeight:500}}>Objective</Typography>
                      <Typography variant="body1" sx={{mb:2}}>Industrial Equipment project object detection aims to recognize and identify Industrial equipment, and useful objects in images as well as understand the content and context.</Typography>
                      <Typography variant="body1" sx={{mb:2}}>The software detectron detects the particular image object and defines the percentage from the image of the product. The Detectron software is implemented using the python language and Libraries.</Typography>
                      {/* <Typography variant="body1" sx={{mb:2}}><a target="_blank" rel="noopener noreferrer" href="http://industrialequipment.24livehost.com:4050">http://industrialequipment.24livehost.com:4050</a></Typography> */}
                      <Typography variant="body1" sx={{mb:2}}><strong>Libraries: </strong>Numpy, Open CV, Torch, Torch Vision, Torch Audio, Pyyaml-5.1 Version.</Typography>
                </Grid>
              </Grid>
        </Container>
    </Box>
    <Box className="NavyColour" sx={{ py:3 }}>
      <Container maxWidth="xl">
          <Grid directions="row" spacing={3} container items sx={{textAlign:'center', alignItems:'center', justifyContent:'space-between'}}>
              <Grid item sm={4} md={2} xs={12}><img src={django} alt="django" width="130" /></Grid>
              <Grid item sm={4} md={2} xs={12}><img src={javascript} alt="javascript" width="120" /></Grid>
              <Grid item sm={4} md={2} xs={12}><img src={postgree} alt="postgree" width="140" /></Grid>
              <Grid item sm={4} md={2} xs={12}><img src={python} width="170" alt="python" /></Grid>
          </Grid>
      </Container>
    </Box>
    <Box className="lightGrey" sx={{ py:7 }}>
        <Container maxWidth="xl">
            <Grid container items display="row" spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography variant="h4" component="h4" sx={{mb:2}}>Features</Typography>
                  <Typography variant="body1" sx={{mb:2}}>
                  <strong>1. Image Annotation</strong>
                  <p>In machine learning and deep learning, image annotation is the process of labelling or classifying an image using text, annotation tools, or both, to show the data features you want your model to recognize on its own.</p>

                  <strong>2. Object Detection</strong>
                  <p>Decetron identifies the objects from the pictures and classifies them into broader categories. Object detection uses the special feature of these objects to classify. It has many applications like image retrieval, video surveillance, face detection, pedestrian detection, vehicle counting, etc.</p>

                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="h4" component="h4" sx={{mb:2}}>Solution Delivered</Typography>
                    <Typography variant="body1" sx={{mb:2}}>
                        <ul>
                          <li>This tool is developed for an industry level.</li>
                          <li>The user will be able to detect the object by uploading the image.</li>
                          <li>The system will process the image and provide the score.</li>
                          <li>We developed this application with 250 images. Right now the system is showing output on 250 images dataset.</li>
                          <li>We have used Roboflow for image annotation.</li>
                        </ul>
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    </Box>
   
    
  </>
  )
}

export default CaseStudyDetail