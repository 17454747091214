import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import womanHolding from '../../../images/womanHolding.webp';
import commitment from '../../../images/commitment.svg';
import assistance from '../../../images/assistance.svg';
import growth from '../../../images/growth.svg';
import transparancy from '../../../images/transparancy.svg';
import CostSaving from '../../../images/CostSaving.svg';
import Compatible from '../../../images/Compatible.svg';
import TimeSaving from '../../../images/TimeSaving.svg';
import Technology from '../../../images/Technology.svg';
import HighlyEfficient from '../../../images/HighlyEfficient.svg';
import { Api } from '../../../Api';
import Loader from "react-js-loader";
import { Helmet } from 'react-helmet';
import ConsultationFormPopup from '../Home/ConsultationFormPopup';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

class ServicesDetails extends React.Component {

        // Constructor 
        constructor(props) {
        super(props);
          
        let path =  window.location.pathname;
        let pathArr = path.split("/");

        this.serviceUrl = pathArr;

          this.state = {
              service: {},
              DataisLoaded: false,
          };

          window.scrollTo({
              top: 0,
              behavior: 'instant',
          });
      }


      componentDidMount = () => {
          fetch(Api.servicesDetails+'/'+this.serviceUrl[2])
              .then((res) => res.json())
              .then((json) => {

                  console.log(json.service_details);
                  this.setState({
                    service: json.service_details,
                      DataisLoaded: true
                  });

              })          
      }

  render() {

    const { DataisLoaded, service } = this.state;
    const list = []

  if (!DataisLoaded) {return <div className={"item"}>
  <Loader type="spinner-circle" bgColor={"rgb(30, 62, 91)"} title={"Loading..."} color={'rgb(30, 62, 91)'} size={100} />
  </div>;}
  else{
  return (
    <>
    <Helmet>
        <link href={window.location.href} rel="canonical"/>
        <title>{service.meta_title}</title>
        <meta name="description" content={service.meta_description} />


        <meta property='og:url' content={window.location.href} />
        <meta property='og:title' content={service.meta_title} />
        <meta property='og:description' content={service.meta_description} />
        <meta property='og:image' content={Api.siteUrl + "images/services/" + service.section_2_image} />

        <meta name='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content={window.location.href} />
        <meta name='twitter:title' content={service.meta_title} />
        <meta name='twitter:description' content={service.meta_description} />
        <meta name='twitter:image' content={Api.siteUrl + "images/services/" + service.section_2_image} />

    </Helmet>
    <Box className="pythonDevelopment" sx={{ py:7, pb:11}}>
      <Container maxWidth="md">
          <Typography variant="h1" component="h1" sx={{mb:3, textAlign:'center', textTransform:'uppercase'}}>{service.title}</Typography>
          <div class="bar"></div>
          <Typography variant="body1" sx={{mb:5, textAlign:'center', fontWeight:500}}>{service.description}</Typography>
      </Container>
      <Container maxWidth="xl">
          <Grid container display="row" items spacing={3} sx={{alignItems:'center'}}>
            <Grid item sm={12} md={6}>
                <img src={Api.siteUrl + "images/services/" + service.section_2_image} alt={service.section_2_img_alt_name} width="100%"/>
            </Grid>
            <Grid item sm={12} md={6}>
              {/* <Typography variant="h5" component="h5" sx={{mb:2, fontWeight:'600'}}>Creating 100% Secure & Stunning Python Websites For Your Business.</Typography>
              <Typography variant="body1" sx={{my:2, textAlign:'justify', fontWeight:500}}>Being a Python Web Development Company, Dotsquares holds certified pioneers in Python website designing & development. With a complete hack of technology, we help you transform your business digitally and grow to exponential heights.</Typography>
                  <Box variant="li" sx={{py:1}}>• 100% Secure Web Solutions.</Box>
                  <Box variant="li" sx={{py:1}}>• Best mobile-friendly websites.</Box>
                  <Box variant="li" sx={{py:1}}>• Create MVPs for Complex Websites.</Box>
                  <Box variant="li" sx={{py:1}}>• Integration of AI and ML.</Box> */}
                  <div dangerouslySetInnerHTML={{ __html:service.section_2_text}} />
            </Grid>
          </Grid>
      </Container>   
    </Box>

    <Box display="block" sx={{py:5}}>
      <Container maxWidth="lg" sx={{textAlign:'center'}}>
          <Grid container items direction="row" sx={{alignItems:'center', textAlign:'center', justifyContent:'center'}}>
            <Typography className="" variant="h5" component="h5" sx={{textAlign:'center', mb:1, fontWeight:500}}>Put Your First Step Forward </Typography>
            <Typography className="" variant="h4" component="h4" sx={{textAlign:'center', mb:3}}>Get Free Project <strong>Consultation And Cost Estimation From Us</strong></Typography>

            {/* <Button variant="contained" color="secondary" size="large" href="/#top">Let’s Talk</Button> */}

            <Popup trigger= {
              <Button variant="contained" color="secondary" size="large">Let’s Talk</Button>}
              modal nested>
              {
                  close => (
                      <div className='modal'>
                          <div className='content'>
                              <ConsultationFormPopup />
                          </div>
                          <div class="popupClose">
                              <Button variant="contained" color="secondary" onClick=
                                  {() => close()}>
                                      X
                              </Button>
                          </div>
                      </div>
                  )
              }
            </Popup>

          </Grid>
      </Container>
    </Box>

    <Box className="NavyColour skewShape white" sx={{ py:20, mt:-13 }}>
      <Container maxWidth="lg">
          <Typography variant="h4" component="h4" sx={{mb:3, textAlign:'center', textTransform:'uppercase'}}>{service.section_3_title}</Typography>
          <div class="bar"></div>
          <Typography variant="body1" sx={{mb:5, textAlign:'center'}}>{service.section_3_desc}</Typography>
      </Container>
       <Container maxWidth="md">
          <Typography variant="h5" component="h5" sx={{mb:4, textAlign:'center', fontWeight:'600'}}>{service.section_3_subtitle}</Typography>
      </Container>
      <Container maxWidth="lg">
      <Grid container display="row" items spacing={3}>
          {
          service.section_three.map((sec, index) => (
            <Grid item sm={12} md={4}>
              <Card sx={{p:2, minHeight: 330}}>
                <Box sx={{textAlign:'center', p:2, minHeight: 90}}><img src={Api.siteUrl + "images/services/" + sec.icon} alt="commitment" width="80px"/></Box>
                <Typography variant="h5" className="NavyColourText" component="h5" sx={{fontWeight:600, mb:2, textAlign:'center', textTransform:'uppercase'}}>{sec.title}</Typography>
                <Typography variant="body1" sx={{mb:2, textAlign:'center', fontWeight:500}}>{sec.description}</Typography>
              </Card>
            </Grid>
             ))
            }
            
          </Grid>
      </Container>   
    </Box>

    <Box className="" sx={{ py:8 }}>
      <Container maxWidth="lg">
          <Typography variant="h4" component="h4" sx={{mb:1, textAlign:'center', textTransform:'uppercase'}}>{service.section_4_title}</Typography>
          <Typography variant="body1" sx={{mb:2, textAlign:'center', fontWeight:500}}>{service.section_4_subtitle}</Typography>
          <div class="bar"></div>
      </Container>
      <Container maxWidth="lg" sx={{my:4}}>
        {
          service.section_4_tech_title.map((title, index) => (
          <Card className="secondaryColor" sx={{p:2, my:2}}>
            <Grid container display="row" items spacing={3} sx={{alignItems:'center'}}>
              <Grid item sm={12} md={4}>
                <Typography variant="h6" className="NavyColourText" component="h6" sx={{fontWeight:700}}>{title}</Typography>
              </Grid>
              <Grid item sm={12} md={8}>
                <Box sx={{display:'flex', justifyContent:'start'}}>
                  <Typography variant="body1" sx={{fontWeight:500, px:2}}>{service.section_4_technologies[index]}</Typography>
                  {/* <Typography variant="body1" sx={{fontWeight:500, px:2}}>Web2Py</Typography>
                  <Typography variant="body1" sx={{fontWeight:500, px:2}}>TurboGears</Typography> */}
                </Box>
              </Grid>
            </Grid>
          </Card>
          ))
        } 
          {/* <Card className="secondaryColor" sx={{p:2, my:2}}>
            <Grid container display="row" items spacing={3} sx={{alignItems:'center'}}>
              <Grid item sm={12} md={4}>
                <Typography variant="h6" className="NavyColourText" component="h6" sx={{fontWeight:700}}>Micro Framework</Typography>
              </Grid>
              <Grid item sm={12} md={8}>
                <Box sx={{display:'flex', justifyContent:'start'}}>
                  <Typography variant="body1" sx={{fontWeight:500, px:2}}>Bottle</Typography>
                  <Typography variant="body1" sx={{fontWeight:500, px:2}}>React</Typography>
                  <Typography variant="body1" sx={{fontWeight:500, px:2}}>Flask </Typography>
                </Box>
              </Grid>
            </Grid>
          </Card>
          <Card className="secondaryColor" sx={{p:2, my:2}}>
            <Grid container display="row" items spacing={3} sx={{alignItems:'center'}}>
              <Grid item sm={12} md={4}>
                <Typography variant="h6" className="NavyColourText" component="h6" sx={{fontWeight:700}}>Libraries</Typography>
              </Grid>
              <Grid item sm={12} md={8}>
                <Box sx={{display:'flex', justifyContent:'start'}}>
                  <Typography variant="body1" sx={{fontWeight:500, px:2}}>NumPy</Typography>
                  <Typography variant="body1" sx={{fontWeight:500, px:2}}>Pandas </Typography>
                  <Typography variant="body1" sx={{fontWeight:500, px:2}}>BeeWare</Typography> 
                </Box>
              </Grid>
            </Grid>
          </Card> */}
      </Container>   
    </Box>
    </>
  )
  }
  }
}

export default ServicesDetails