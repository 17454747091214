//Live API
const apiUrl = 'https://python.dotsquares.com/laravel/public/api/';
const siteUrl = 'https://python.dotsquares.com/laravel/public/';

// // //Demo API
// const apiUrl = 'https://subsitepython.24livehost.com/laravel/public/api/';
// const siteUrl = 'https://subsitepython.24livehost.com/laravel/public/';

// local API
// const apiUrl = 'http://localhost/subsitepython/public/api/';
// const siteUrl = 'http://localhost/subsitepython/public/';

export const Api = {
    apiUrl: apiUrl,
    siteUrl: siteUrl,
    homePage: apiUrl+'home-page-data',
    newConsultationEntry: apiUrl+'new-consultation-entry',
    blogCategoriesWithPostsList: apiUrl+'blog-categories-with-posts-list',
    blogsList: apiUrl+'blogs-list',
    blogDetails: apiUrl+'blog-details',
    servicesList: apiUrl+'services-list',
    servicesDetails: apiUrl+'service-details'
}                   