import React from 'react'
import Topbanner from './Topbanner'
import Ourservice from './Ourservice'
import WhyChoose from './WhyChoose'
import OurExpertise from './OurExpertise'
import Awards from './Awards'
import CaseStudy from './CaseStudy'
import Clients from './Clients'
import GreatReviews from './GreatReviews'
import Industry from './Industry'
import { Helmet } from 'react-helmet';

class Home extends React.Component {

  render() {
    
    
    const items  = this.props.items;

    return (
    <>
       <Helmet>
            {/* <meta property='og:url' content={window.location.href} />
            <meta property='og:title' content='Pythonsquares: A Reliable Python Development Company' />
            <meta property='og:description' content='Pythonsquares is your one-stop solution to develop versatile Python Projects. Outsource your requirements or hire certified Python Developers (seasoned players).' />
            <meta property='og:image' content={window.location.href + "static/media/whyChoose.d0ee3254.jpg"} /> */}


            <meta property="og:type" content="website" />
            <meta property="og:title" content="'Pythonsquares: A Reliable Python Development Company" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content={window.location.href + "static/media/whyChoose.d0ee3254.jpg"} />
            <meta property="og:description" content="Pythonsquares is your one-stop solution to develop versatile Python Projects. Outsource your requirements or hire certified Python Developers (seasoned players)."></meta>


            <meta name='twitter:card' content='summary_large_image' />
            <meta property='twitter:url' content={window.location.href} />
            <meta name='twitter:title' content='Pythonsquares: A Reliable Python Development Company' />
            <meta name='twitter:description' content='Pythonsquares is your one-stop solution to develop versatile Python Projects. Outsource your requirements or hire certified Python Developers (seasoned players).' />
            <meta name='twitter:image' content={window.location.href + "static/media/whyChoose.d0ee3254.jpg"} />
        </Helmet>
        

      
      <main>

        <Topbanner banner = {items.banner} />
        <Ourservice services = {items.services} />
        <WhyChoose whyChooses = {items.why_chooses} />
        <OurExpertise expertiseSection = {items.expertise_section} />
        {/* <WebDevelopment webDevelopment = {items.web_development} /> */}
        <Industry />
        <GreatReviews />
        <Awards />
        <CaseStudy />
        
        <Clients />
        
        {/* <HirePython /> */}
      </main>
      </>
    )

  } 
 

}

export default Home;