import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import project from '../../../images/project.jpg';
import projectTwo from '../../../images/projectTwo.jpg';
import { Helmet } from 'react-helmet';

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

const items = [
  <Box className="item" data-value="1" sx={{p:1}}>
    <Box className="casestudyImage"><a href="/casestudies/megasearch"><img alt="projects1" width="100%" src={project} /></a></Box>
    <Box className="casestudyDes" sx={{p:2}}>
        <Typography className="white" variant="h5" component="h5" sx={{mb:3, fontWeight:600}}><a className="white" href="/casestudies/megasearch">MEGA SEARCH</a></Typography>
        <ul>
            <li>Technology: Python (Django), JQuery and Postgre SQL, Video Streaming, Stripe</li>
            <li>Industry: Utilities </li>
            <li>Location: UK</li>
            <li>Team Size: 4-6 members</li>
        </ul>
    </Box>
  </Box>,
  <Box className="item" data-value="2" sx={{p:1}}>
    <Box className="casestudyImage"><a href="/casestudies/industrialequipment"><img alt="projects2" width="100%" src={projectTwo} /></a></Box>
    <Box className="casestudyDes" sx={{p:2}}>
        <Typography className="white" variant="h5" component="h5" sx={{mb:3, fontWeight:600}}><a className="white" href="/casestudies/industrialequipment">INDUSTRIAL EQUIPMENT</a></Typography>
        <ul>
            <li>Technology: Python (Django), JQuery and PostgreSQL</li>
            <li>Libraries: Numpy, Open CV, Torch, Torch Vision, Torch Audio, Pyyaml-5.1 Version </li>
            <li>Industry: Utilities </li>
            <li>Location: UK</li>
            <li>Team Size: 4-6 members</li>
        </ul>
    </Box>
  </Box>,
  <Box className="item" data-value="3" sx={{p:1}}>
    <Box className="casestudyImage"><a href="/casestudies/megasearch"><img alt="projects3" width="100%" src={project} /></a></Box>
    <Box className="casestudyDes" sx={{p:2}}>
        <Typography className="white" variant="h5" component="h5" sx={{mb:3, fontWeight:600}}><a className="white" href="/casestudies/megasearch">MEGA SEARCH</a></Typography>
        <ul>
            <li>Technology: Python (Django), JQuery and Postgre SQL, Video Streaming, Stripe</li>
            <li>Industry: Utilities </li>
            <li>Location: UK</li>
            <li>Team Size: 4-6 members</li>
        </ul>
    </Box>
  </Box>,
  <Box className="item" data-value="4" sx={{p:1}}>
    <Box className="casestudyImage"><a href="/casestudies/industrialequipment"><img alt="projects4" width="100%" src={projectTwo} /></a></Box>
    <Box className="casestudyDes" sx={{p:2}}>
        <Typography className="white" variant="h5" component="h5" sx={{mb:3, fontWeight:600}}><a className="white" href="/casestudies/industrialequipment">INDUSTRIAL EQUIPMENT</a></Typography>
        <ul>
            <li>Technology: Python (Django), JQuery and Postgre SQL</li>
            <li>Libraries: Numpy, Open CV, Torch, Torch Vision, Torch Audio, Pyyaml-5.1 Version </li>
            <li>Industry: Utilities </li>
            <li>Location: UK</li>
            <li>Team Size: 4-6 members</li>
        </ul>
    </Box>
  </Box>
];


const CaseStudy = () => {
  return (
    <>
    <Helmet>
        <link href="https://python.dotsquares.com/casestudies" rel="canonical"/>
        <title>Pythonsquares for trusted Python Web Solutions</title>
        <meta name="description" content="Pythonsquares gets vocal about some of the successful Python case studies. Know their journey, challenges and the tailored solutions delivered by us." />
    </Helmet>
    
    <Box className="NavyColour" sx={{ py:7 }}>
        <Container maxWidth="md">
            <Typography className="white" variant="h4" component="h4" sx={{mb:3, textAlign:'center', textTransform:'uppercase'}}>Case <strong>Studies</strong></Typography>
            <div class="bar"></div>
            <Typography className="white" variant="body1" sx={{mb:5, textAlign:'center'}}>Python is moving upward unexpectedly and the demand is sure to continue with no sign of dampening anytime soon. Python gives a wide scope of frameworks to developers and here are the frameworks we work on.</Typography>
        </Container>    
        
        <AliceCarousel
            mouseTracking
            items={items}
            responsive={responsive}
            controlsStrategy="alternate"
            autoPlay="true"
            infinite="true"
            animationDuration="4000"
        /> 
    </Box>
    </>
  )
}

export default CaseStudy