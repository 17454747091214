import React from 'react'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Api } from '../../../Api';
import Expertise from '../../../images/Expertise.png';

class OurExpertise extends React.Component {
    render() {

        var leftColumn = [];
        var middleColumn = [];
        var rightColumn = [];
        
        for (var i = 0; i < this.props.expertiseSection.expertises.length; i++) {

            if(i<3){
                leftColumn.push(<ListItem>
                    <div className="expertiseIcon"><img src={Api.siteUrl+"images/expertise/"+this.props.expertiseSection.expertises[i].icon} alt={this.props.expertiseSection.expertises[i].icon} /></div>
                    <ListItemText primary={this.props.expertiseSection.expertises[i].title} />
                </ListItem>);
            } else if (i<7){
                middleColumn.push(<ListItem>
                     <div className="expertiseIcon"><img src={Api.siteUrl+"images/expertise/"+this.props.expertiseSection.expertises[i].icon} alt={this.props.expertiseSection.expertises[i].icon} /></div>
                    <ListItemText primary={this.props.expertiseSection.expertises[i].title} />
                </ListItem>);
            } else if(i<10){
                rightColumn.push(<ListItem>
                    <div className="expertiseIcon"><img src={Api.siteUrl+"images/expertise/"+this.props.expertiseSection.expertises[i].icon} alt={this.props.expertiseSection.expertises[i].icon} /></div> 
                    <ListItemText primary={this.props.expertiseSection.expertises[i].title} />
                </ListItem>);
            }
        }


        return (
            <Grid className="lightGrey" sx={{ py:10 }}>
                <Container maxWidth="lg">
                    <Typography className="" variant="h4" component="h4" sx={{textTransform:'uppercase', mb:2, textAlign:'center'}}>Our <strong>Expertise</strong></Typography>
                    <div class="bar"></div>
                </Container>    
                <Container maxWidth="xl">
                    <img src={Expertise} alt="Expertise" width="100%" />
                </Container>
            </Grid>
        )
    }
}

export default OurExpertise