import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import CardContent from '@mui/material/CardContent';
import portfolio01 from '../../../images/portfolio01.jpg';
import portfolio03 from '../../../images/portfolio03.jpg';
import projectTwo from '../../../images/projectTwo.jpg';
import { NavLink, useNavigate } from "react-router-dom";


const CaseStudy = () => {

    const navigate = useNavigate();
    const handleClick = (route) => {
        navigate(route); 
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        });
        
        document.querySelector('.cancel-icon').click();
    }

  return (
    <Box sx={{ py:7, textAlign:'center' }}>
        <Container maxWidth="md">
            <Typography className="" variant="h4" component="h4" sx={{textTransform:'uppercase', mb:1, textAlign:'center'}}>Case <strong>Studies</strong></Typography>
            <div class="bar"></div>
            <Typography variant="body1" sx={{fontWeight:'500', textAlign:'center'}}>Our vetted & handpicked Python developers has successfully deployed Python projects and set an excellent example of programming.</Typography>
        </Container>    
        <Container maxWidth="xl">
            <Grid container direction="row" spacing={3} sx={{justifyContent:'center', textAlign:'center', my:5}}>
                <Grid item sm={3}>
                    <Card variant="outlined" sx={{textAlign:'center'}}>
                        <CardContent style={{padding:'10px'}}>
                            <Link href="/casestudies/MegaSearch"><img width="100%" src={portfolio01} alt="portfolio01" /></Link>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={3}>
                    <Card variant="outlined" sx={{textAlign:'center'}}>
                        <CardContent style={{padding:'10px'}}>
                            <Link href="/casestudies/industrialequipment"><img width="100%" src={projectTwo} alt="portfolio03" /></Link>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Button variant="contained" color="secondary" size="large" onClick = {() => handleClick("casestudies")}>View More</Button>
        </Container>
    </Box>
  )
}

export default CaseStudy