import { Routes, Route } from "react-router-dom";
import React from 'react';
import './App.css';
import './scss/global.css';
import Header from "./components/Elements/Header";
import Footer from "./components/Elements/Footer";
import Home from "./components/Pages/Home/Home";
import WhyPython from "./components/Pages/WhyPython/WhyPython";
import CaseStudy from "./components/Pages/CaseStudy/CaseStudy";
import Projects from "./components/Pages/Projects/Projects";
import Services from "./components/Pages/Services/Services";
import WebDevelop from "./components/Pages/Services/WebDevelop";
import { Api } from './Api';
import LoadingBar from 'react-top-loading-bar';
import MegaSearch from "./components/Pages/CaseStudy/MegaSearch";
import IndustrialEquipment from "./components/Pages/CaseStudy/IndustrialEquipment";
import Certifications from "./components/Pages/Certifications/Certifications";
import ThankYou from "./components/Pages/ThankYou/ThankYou";
import {News, NewsWithRouter} from "./components/Pages/News/News";
import NewsDetails from "./components/Pages/News/NewsDetails";
import { Helmet } from 'react-helmet';
import ServicesDetails from "./components/Pages/Services/ServicesDetails";

class App extends React.Component {

  // Constructor 
  constructor(props) {
    super(props);

    this.state = {
        items: [],
        DataisLoaded: false,
        progress: 20
    };
  }
  

  setProgress = (progress) => {
    this.setState({progress:progress});
  }

  // ComponentDidMount is used to
  // execute the code 
  componentDidMount = () => {
    //console.log('I was triggered during componentDidMount');
    this.setProgress(40);
    
      fetch(Api.homePage)
          .then((res) => res.json())
          .then((json) => {
            this.setProgress(60);
              this.setState({
                  items: json,
                  DataisLoaded: true
              });
              this.setProgress(80);
              this.setProgress(100);
          })

          //get referrer site url
        let referrer = document.referrer;
        localStorage.setItem("referrerURL",referrer);

        let siteURL =  window.location.href;
        localStorage.setItem("siteURL",siteURL);
        
  }

  render() {
    const { DataisLoaded, items } = this.state;

    if (!DataisLoaded) return <div>
     <LoadingBar
        color='#f4861a'
        progress={this.state.progress}
        height={4}
        onLoaderFinished={() =>  this.setProgress(0)}
      /> </div> ;
    return (
      <div className="App">
        <Helmet>
          <link href="https://python.dotsquares.com" rel="canonical"/>
          <title>Pythonsquares: A Reliable Python Development Company</title>
          <meta name="description" content="Pythonsquares is your one-stop solution to develop versatile Python Projects.  Outsource your requirements or hire certified Python Developers (seasoned players)." />
        </Helmet>
         <LoadingBar
          color='#f4861a'
          progress={this.state.progress}
          height={4}
          onLoaderFinished={() =>  this.setProgress(0)}
        /> 
        <Header />
        <div className="middlePart">
          <Routes>
              <Route path="/" element={<Home items = {items} />} />
              <Route path="/" element={<Home  items = {items} />} />
              <Route path="/whypython" element={<WhyPython />} />
              <Route path="/casestudies" element={<CaseStudy />} />
              <Route path="/casestudies/megasearch" element={<MegaSearch />} />
              <Route path="/casestudies/industrialequipment" element={<IndustrialEquipment />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/certifications" element={<Certifications />} />
              <Route path="/thankyou" element={<ThankYou />} />
              {/* <Route path="services" element={<Services setProgress={this.setProgress} services = {items.services} />} /> */}
              {/* <Route path="/webdevelop" element={<WebDevelop />} /> */}
              <Route path="/news" element={<NewsWithRouter />} />
              <Route path="/services/:slug" element={<ServicesDetails />} /> 
              <Route path="/news/:slug" element={<NewsDetails />} />
             
          </Routes>
        </div>
        <Footer services = {items.services} />
        
      </div>
    );
  }
}


export default App;